@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

* {
    font-family: 'Inter', sans-serif;
}

:root {
    --primary-blue: #2B2BD6;
}

.primary-color {
    color: var(--primary-blue);
}

.primary-color-bg {
    background-color: var(--primary-blue);
}

.min-40 {
    min-width: 40%;
}
.min-50 {
    min-width: 50%;
}

@media print {
    header, footer, aside, form {
        display: none;
    }
   .noprint {
      display: none;
   }
}
@tailwind base;

@layer base {
    .editor h1 {
        @apply text-4xl;
    }
    .editor h2 {
        @apply text-2xl;
    }
    .editor h3 {
        @apply text-xl;
    }
    .editor h4 {
        @apply text-lg;
    }
    .editor h5 {
        @apply text-base;
    }
    .editor h6 {
        @apply text-sm;
    }

}

@tailwind components;
@tailwind utilities;

@layer components {
    /* utils */
    .header-nav {
        @apply w-full sm:px-6;
        @apply px-4 py-2 bg-white shadow-lg md:shadow-none;
        @apply flex flex-wrap items-center;
        @apply lg:px-16 lg:py-1 max-w-7xl;
    }

    .header-home {
        @apply sm:mx-8 sm:rounded-md;
        @apply flex flex-col flex-wrap;
        @apply p-2 mx-2 mt-8 mb-4 text-white rounded-lg;
        @apply md:flex-row md:p-12 md:mx-12 md:mt-8 md:mb-6;
    }

    .nav-link-ul {
        @apply items-center justify-between pt-4 text-base lg:pt-0;
        @apply lg:flex;
    }

    .nav-link {
        @apply block px-0 py-3 lg:p-4;
        @apply text-xl font-semibold;
        @apply border-b-2 border-transparent hover:border-indigo-400;
    }

    /* Card */
    .article-card-container {
        @apply md:max-w-md;
        @apply my-4 overflow-hidden rounded-md shadow-lg md:m-4;
        @apply md:h-auto lg:h-auto;
    }

    .article-card-title {
        @apply mb-4 text-2xl font-bold text-center text-gray-600;
    }
    .article-card-slug {
        @apply mb-6 text-sm font-normal text-center text-gray-700;
    }
    .article-card-author {
        @apply mt-2 text-sm font-medium text-center text-gray-500;
    }

    /* cards grid left pane home */
    .card-grid-home {
        @apply grid grid-flow-row grid-cols-1 grid-rows-5;
        @apply lg:grid-cols-2 lg:grid-rows-3;
        /*@apply border-2 lg:w-3/5 md:mx-8;*/
        @apply w-full lg:w-3/4 md:w-2/3;
    }
}
